import React, { useState, useEffect } from "react";

import styles from "./HeroSlider.module.css";
import { useAppContext } from "../../AppContext";
export default function HeroSlider() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const miniSlides = [
    {
      title: `Premier Dental Care in Gachibowli, Hyderabad | Dental Planet Elite`,
      description: `At  our Dental Clinic, we provide affordable and personalized 
      dental care in Hyderabad.`,
    },
    {
      title: `Best Dental
    Clinic In Gachibowli`,
      description: `Experience top-quality dental care with us.
    Our skilled team is committed to improving your oral health and 
    enhancing your smile. Schedule an appointment with us today!`,
    },
    {
      title: "Best Dental Aligners, Hyderabad",
      description:
        "We provide emergency dental services to relieve your pain and discomfort. Our experienced team is here to assist you during dental emergencies, ensuring prompt and effective treatment.",
    },
  ];
  const slides = [
    {
      title: `Premier Dental Care 
      <br/>
      Gachibowli,
      <br/>
      Hyderabad
      <br/>`,
      description: `At  our Dental Clinic, we provide affordable and personalized 
      dental care in Hyderabad.`,
    },
    {
      title: `Best Dental
      <br/>
      Clinic in Gachibowli`,
      description: `Experience top-quality dental care with us.
      Our skilled team is committed to improving your oral health and 
      enhancing your smile. Schedule an appointment with us today!`,
    },
    {
      title: "Best Dental Aligners, Hyderabad",
      description:
        "We provide emergency dental services to relieve your pain and discomfort. Our experienced team is here to assist you during dental emergencies, ensuring prompt and effective treatment.",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 8000); // Change the interval duration (in milliseconds) as needed

    // Clear the interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [currentSlide]);

  const [finalSlides, setFinalSlides] = useState(
    windowWidth <= 730 ? miniSlides : slides
  );
  return (
    <div className={styles.sliderContainer}>
      {finalSlides.map((slide, index) => (
        <div
          key={index}
          className={
            index === currentSlide
              ? `${styles.slide} ${styles.active}`
              : styles.slide
          }
        >
          <div className={styles.heroContainer}>
            <div className={styles.innerHeader}>
              <h1
                dangerouslySetInnerHTML={{ __html: slide.title }}
                className={styles.heroHeader}
              />
              <h3
                dangerouslySetInnerHTML={{ __html: slide.description }}
                className={styles.heroDescription}
              />
              <div className={styles.ctaBtn}>
                <a
                  href="#schedule-section"
                  onClick={handleScrollToSchedule}
                  className={styles.btnPrimary}
                >
                  Book An Appointment
                </a>
                <a href="/contact" className={styles.btnSecondary}>
                  Or Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
