import React from "react";
import { useInView } from "react-intersection-observer";

import styles from "./ProsthodonticLocation.module.css";
import { IonIcon } from "@ionic/react";
import { location } from "ionicons/icons";
import { call,logoWhatsapp } from "ionicons/icons";

function ProsthodonticLocation() {
  const [map, mapView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [detailsRef, detailsRefView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  const [hoursRef, hoursRefView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <>
      <section className={styles.locationSection}>
        <div className={styles.container}>
          <div
            className={`${styles.details} ${
              detailsRefView ? styles.animate : ""
            }`}
            ref={detailsRef}
          >
            <h3>Dental Planet</h3>
            <p>
              We’re conveniently located in Vinayak Nagar, Gachibowli,
              Hyderabad. Find us on the ground floor of the building. Plenty of
              free parking is available. Our clinic is easily accessible by
              public transport, with bus stops and metro stations nearby. We
              look forward to welcoming you to our state-of-the-art facility.
            </p>
            <div className={styles.location}>
              <IonIcon icon={location} className={styles.locationIcon} />
              <p>
                1-37/1/125, Old Mumbai Highway, Vinayak Nagar, Gachibowli,
                Hyderabad, Telangana, 500032
              </p>
            </div>
            <div className={styles.callContent}>
              <IonIcon icon={call} className={styles.timeIcon} />
              <p>Call 040-40 24 7676 / (+91) 9908873366</p>
            </div>
            <a
              href="https://wa.me/917095917676?text=hello%2C%20Dental%20Planet"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.callContentWhatsapp}>
                <IonIcon icon={logoWhatsapp} className={styles.timeIcon} />
                <p>WhatsApp 7095917676</p>
              </div>
            </a>
          </div>
          <div
            className={`${styles.hours} ${hoursRefView ? styles.animate : ""}`}
            ref={hoursRef}
          >
            <h3>Hours</h3>
            <div className={styles.timings}>
              <ul className={styles.time}>
                <li>
                  <h4>Monday</h4>
                </li>
                <li>
                  <h4>Tuesday</h4>
                </li>
                <li>
                  <h4>Wednesday</h4>
                </li>
                <li>
                  <h4>Thursday</h4>
                </li>
                <li>
                  <h4>Friday</h4>
                </li>
                <li>
                  <h4>Saturday</h4>
                </li>
                <li>
                  <h4>Sunday</h4>
                </li>
              </ul>
              <ul className={styles.time}>
                <li>
                  <h4>9AM - 9PM</h4>
                </li>
                <li>
                  <h4>9AM - 9PM</h4>
                </li>
                <li>
                  <h4>9AM - 9PM</h4>
                </li>
                <li>
                  <h4>9AM - 9PM</h4>
                </li>
                <li>
                  <h4>9AM - 9PM</h4>
                </li>
                <li>
                  <h4>9AM - 9PM</h4>
                </li>
                <li>
                  <h4>9AM - 9PM</h4>
                </li>
              </ul>
              {/* <div className={styles.time}>
          <h4>Monday</h4>
          <div className={styles.empty}></div>
          <h4>9AM - 9PM</h4>
        </div>
        <div className={styles.time}>
          <h4>Tuesday</h4>
          <div className={styles.empty}></div>
          <h4>9AM - 9PM</h4>
        </div>
        <div className={styles.time}>
          <h4>Wednesday</h4>
          <div className={styles.emptyWed}></div>
          <h4>9AM - 9PM</h4>
        </div>
        <div className={styles.time}>
          <h4>Thursday</h4>
          <div className={styles.emptyThur}></div>
          <h4>9AM - 9PM</h4>
        </div>
        <div className={styles.time}>
          <h4>Friday</h4>
          <div className={styles.emptyFri}></div>
          <h4>9AM - 9PM</h4>
        </div> */}
            </div>
          </div>
        </div>
        <div
          className={`${styles.ContactLocation} ${
            mapView ? styles.animate : ""
          }`}
          ref={map}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3821201914784!2d78.35611387515607!3d17.441415501246517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9395b95ec16d%3A0xbedbf17b1d91821!2sDental%20Planet%20Elite%20%7C%20Invisalign%20%26%20Clear%20Aligners%20%7C%20Root%20Canal%20Treatment%20%26%20Teeth%20Cleaning%2FWhitening!5e0!3m2!1sen!2sin!4v1713536368939!5m2!1sen!2sin"
            loading="lazy"
            allowFullScreen
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      </section>
    </>
  );
}

export default ProsthodonticLocation;
