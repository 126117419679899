import React from "react";

import { useInView } from "react-intersection-observer";
import styles from "./Footer.module.css";

function Footer({ dentistry }) {
  const [navRef, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });

  const homeProperty = {
    startColor: "#323780",
    endColor: "#11122b",
  };

  const generalProperty = {
    startColor: "#787b7d",
    endColor: "#545658",
  };

  const prosthodonticProperty = {
    startColor: "#94b3b9",
    endColor: "#656977",
  };

  const orthodonticProperty = {
    startColor: "#aed1f4",
    endColor: "#7a92ab",
  };

  const pediatricProperty = {
    startColor: "#978ab1",
    endColor: "#5f566f",
  };
  const endonticProperty = {
    startColor: "#64bae6",
    endColor: "#213e4c",
  };

  const periodontalProperty = {
    startColor: "#dbb754",
    endColor: "#e3c77a",
  };

  const oralSurgeryProperty = {
    startColor: "#d099d9",
    endColor: "#926b98",
  };
  const styleProperties = {
    home: homeProperty,
    general: generalProperty,
    prosthodontic: prosthodonticProperty,
    orthodontic: orthodonticProperty,
    pediatric: pediatricProperty,
    endontic: endonticProperty,
    periodontal: periodontalProperty,
    oralSurgery: oralSurgeryProperty,
  }[dentistry];
  return (
    <section
      className={styles.footerComponet}
      ref={navRef}
      style={{
        background: `linear-gradient(to right,${styleProperties.startColor} , ${styleProperties.endColor})`,
      }}
    >
      <div className={styles.footerSection}>
        <div className={`${styles.navigation} ${inView ? styles.animate : ""}`}>
          <h4 className={styles.footerheader}>Navigation</h4>
          <div className={styles.navElements}>
            <a href="/">Home</a>
            <a href="/about">About</a>
          </div>
        </div>
        <div className={styles.services}>
          <h4
            className={`${styles.footerheader} ${inView ? styles.animate : ""}`}
          >
            Services
          </h4>
          <div
            className={`${styles.serviceElement} ${
              inView ? styles.animate : ""
            }`}
          >
            <a href="/general">General Dentistry</a>
            <a href="/prosthodontic">Prosthodontic Dentistry</a>
            <a href="/orthodontic">Orthodontic Dentistry</a>
            <a href="/pediatric">Pediatric Dentistry</a>
            <a href="/endodontic">Endodontic Dentistry</a>
            <a href="/periodontal">Periodontal Dentistry</a>
            <a href="/oralsurgery">Oral Surgery </a>
          </div>
        </div>
        <div
          className={`${styles.patientInfo} ${inView ? styles.animate : ""}`}
        >
          <h4 className={styles.footerheader}>Patient Info</h4>
          <div className={styles.patientElement}>
            <a href="/patient/FirstVisit">First Visit</a>
            <a href="/patient/NewPatients">New Patients</a>
            <a href="/patient/Scheduling">Scheduling</a>
            <a href="/patient/PatientRegistration">Patient Registration</a>
          </div>
        </div>
        <div className={`${styles.location} ${inView ? styles.animate : ""}`}>
          <h4 className={styles.footerheader}>Location</h4>
          <div className={styles.locationElement}>
            <a href="#"> 1-37/1/125, Old Mumbai Highway,</a>
            <a href="#">Vinayak Nagar, Gachibowli,</a>
            <a href="#">Hyderabad, Telangana, 500032</a>
          </div>
        </div>
      </div>
      <div className={styles.footerCopyright}>
        <h7 className={styles.copyright}>
          {" "}
          © Copyright 2024. Dental Planet. All Rights Reserved.{" "}
        </h7>
      </div>
    </section>
  );
}

export default Footer;
