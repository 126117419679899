import React, { useState, useEffect } from "react";

import styles from "./PediatricHero.module.css";
import { useAppContext } from "../../AppContext";

export default function PediatricHero() {
  const { setDisableAnimations, setIsScrolling } = useAppContext();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleScrollToSchedule = () => {
    setDisableAnimations(true);
    setIsScrolling(true);
    const scheduleSection = document.getElementById("schedule-section");
    if (scheduleSection) {
      scheduleSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <section className={styles.heroSection}>
        <div className={styles.heroContainer}>
          <div className={styles.innerHeader}>
            <h3 className={styles.heroHeader}>
              {windowWidth > 730 ? (
                <>
                  <span>Best Dental Clinic </span>
                  <span>for Kids</span>
                </>
              ) : (
                <>Best Dental Clinic for kids</>
              )}
            </h3>
            <p className={styles.heroDescription}>
              {windowWidth > 730 ? (
                <>
                  We just want you to be healthy<br></br> and smiling happily.
                </>
              ) : (
                <>We just want you to be healthy and smiling happily.</>
              )}
            </p>
            <div className={styles.ctaBtn}>
              <a
                href="#schedule-section"
                onClick={handleScrollToSchedule}
                className={styles.btnPrimary}
              >
                Book Appointment
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
