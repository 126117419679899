import React, { useState, useEffect } from "react";

import styles from "./AboutHero.module.css";
export default function AboutHero() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.innerHeader}>
          <h3 className={styles.heroDescription}>
            <span>About Dental Planet</span>
          </h3>
          <p className={styles.heroHeader}>
            {windowWidth > 730 ? (
              <>
                Trusted by families <br />
                in Hyderabad.
              </>
            ) : (
              <>Truested by Familes in Hyderabad</>
            )}
          </p>
          <div className={styles.ctaBtn}>
            <a href="#" className={styles.btnPrimary}>
              Book Appointment
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
