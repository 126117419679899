import React from "react";

import styles from "./DentistryComponent.module.css";
import { IonIcon } from "@ionic/react";
import { useInView } from "react-intersection-observer";
import { checkmark, checkmarkCircle } from "ionicons/icons";
import dentistryImg from "../../images/dentistryImg.webp";
import { useAppContext } from "../../AppContext";

function DentistryComponent() {
  const { isScrolling, disableAnimations } = useAppContext();
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures animation triggers only once
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.aroundBorder}>
          <div
            ref={ref}
            className={`${styles.content} ${
              inView && !isScrolling ? styles.animate : ""
            }`}
          >
            <h2 className={styles.dentistryHeader}>Dentistry Done Right</h2>
            <p className={styles.dentistryDescription}>
              Dental Care from kids to seniors, all are embraced in our patient
              family! Committed to cultivating lasting connections through
              uplifting encounters, showcasing
            </p>
            <ul className={styles.items}>
              <li className={styles.item}>
                <IonIcon icon={checkmark} className={styles.itemIcon} />
                <p className={styles.itemText}>Affordable Dental Clinic</p>
              </li>
              <li className={styles.item}>
                <IonIcon icon={checkmark} className={styles.itemIcon} />
                <p className={styles.itemText}>Trusted Dental Clinic</p>
              </li>
              <li className={styles.item}>
                <IonIcon icon={checkmark} className={styles.itemIcon} />
                <p className={styles.itemText}>Best Dental Care</p>
              </li>
            </ul>
            <div className={styles.tagLine}>
              <IonIcon icon={checkmarkCircle} className={styles.itemIcon} />
              <span>
                "Our pledge is our integrity. We commit to getting it right, on
                time, and at a just value."
              </span>
            </div>
          </div>
          <div className={styles.imageContnet}>
            <img
              className={`${styles.dentistryImg} ${
                inView && !isScrolling ? styles.animate : ""
              }`}
              src={dentistryImg}
              alt="Image of the Dental Clinic"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DentistryComponent;
